import axios from "../../../utils/axios";

export const GetCustomerEffortChart = async (
  business_unit_uuid,
  end_date,
  start_date,
  support_channel
) => {
 const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  let response = await axios(
    process.env.REACT_APP_ANALYTICS +
      `customer_effort/chart/${localStorage.getItem("ci")}`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
        business_unit_uuid,
        end_date,
        start_date,
        support_channel,
        timezone:timeZone
        ,
      },
    }
  );
  return { data: response.data, status: response.status };
};
