import { createSlice } from "@reduxjs/toolkit";

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState: {
    isDeleteVisible: false,
   
    paginationDetails: { page: 0, pageSize: 10 },
    rowCount: 0,
    tableRows: [],
    selectionModel:{},
    mapping_paginationDetails: { page: 0, pageSize: 10 },
    aibot_paginationDetails: { page: 0, pageSize: 10 },
    slack_paginationDetails: { page: 0, pageSize: 10 },
    inbox_paginationDetails: { page: 0, pageSize: 10 },
    allRequest_paginationDetails: { page: 0, pageSize: 10 },
    channel_mapping_paginationDetails: { page: 0, pageSize: 5 },
    files_paginationDetails: { page: 0, pageSize: 5 },
    obs_paginationDetails: { page: 0, pageSize: 5 },




 
  },
  reducers: {
    updateIsDelete: (state, action) => {
      state.isDeleteVisible = action.payload;
    },
   
    updatePagination: (state, action) => {
      state.paginationDetails = action.payload;
    },
  
 
    updateAllRequestPagination: (state, action) => {
      state.allRequest_paginationDetails = action.payload;
    },
    updateInboxPagination: (state, action) => {
      state.inbox_paginationDetails = action.payload;
    },
    updateObsPagination:(state,action)=>{
      state.obs_paginationDetails=action.payload
    },
    updateMappingPagination: (state, action) => {
      state.mapping_paginationDetails = action.payload;
    },
    updateAibotPagination: (state, action) => {
      state.aibot_paginationDetails = action.payload;
    },
    updateSlackPagination: (state, action) => {
      state.slack_paginationDetails = action.payload;
    },
    updateFilesPagination:(state,action)=>{
state.files_paginationDetails=action.payload
    },
    updateChannelMappingPagination:(state,action)=>{
      state.channel_mapping_paginationDetails=action.payload
    },
  
    updateRowCount: (state, action) => {
      state.rowCount = action.payload;
    },
    updateTableRows: (state, action) => {
      state.tableRows = action.payload;
    },
    updateSelectionModelChange: (state, action) => {
      state.selectionModel = action.payload;
      if (Object.keys(state.selectionModel).length > 0) state.isDeleteVisible = true;
      if (Object.keys(state.selectionModel).length === 0) state.isDeleteVisible = false;
    },
    deleteSelection: (state, action) => {
      state.tableRows = state.tableRows.filter(
        (r) => !state.selectionModel.includes(r.id)
      );
      state.selectionModel = [];
    },
  },
});

export const {
  updateIsDelete,
  updateCheckedBusinessUnit,
  updateCheckedDataSource,
  updatePagination,
  updateRowCount,
  updateTableRows,
  updateSelectionModelChange,
  deleteSelection,
updateAibotPagination,
  updateMappingPagination,
  updateAllRequestPagination,
  updateSlackPagination,
  updateInboxPagination,
  updateChannelMappingPagination,
  updateFilesPagination,
  updateObsPagination

} = dataTableSlice.actions;
export default dataTableSlice.reducer;
