import axios from "../../../utils/axios";

export const GetAllSubDatasourceByModules = async (module_uuids) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `sub_datasource/${localStorage.getItem("ci")}/by_modules`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{
        module_uuids,
        

      }
     
    
    }
  );
  return { data: response.data, status: response.status };
};

export const GetSubDatasourceByBu = async (business_unit_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `sub_datasource/${localStorage.getItem("ci")}/${business_unit_uuid}/with_default_selections?module_uuid=${localStorage.getItem("mi")}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    
     
    
    }
  );
  return { data: response.data, status: response.status };
};

