import axios from "../../../utils/axios";

export const GetActiveChannels = async () => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `channel/${localStorage.getItem("ci")}/active`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};


export const GetChannelByName = async (channel_name) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `channel/${localStorage.getItem("ci")}/name/${channel_name}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};



export const RefreshApiKeyForChannel = async (channel_uuid) => {
  let response = await axios(
    process.env.REACT_APP_DATA_INTEGRATION+
    `channel/${localStorage.getItem("ci")}/${channel_uuid}/api_key`,
    {
      method: "PUT",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
     
    
    }
  );
  return { data: response.data, status: response.status };
};

