import React from 'react'

function LeaderboardIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" opacity={0.8} xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9173 9.08073V3.41406C17.9173 2.16406 17.384 1.66406 16.059 1.66406H12.6923C11.3673 1.66406 10.834 2.16406 10.834 3.41406V9.08073C10.834 10.3307 11.3673 10.8307 12.6923 10.8307H16.059C17.384 10.8307 17.9173 10.3307 17.9173 9.08073Z" fill="#071942"/>
    <path d="M9.16732 10.9141V16.5807C9.16732 17.8307 8.63399 18.3307 7.30899 18.3307H3.94232C2.61732 18.3307 2.08398 17.8307 2.08398 16.5807V10.9141C2.08398 9.66406 2.61732 9.16406 3.94232 9.16406H7.30899C8.63399 9.16406 9.16732 9.66406 9.16732 10.9141Z" fill="#071942"/>
    <path d="M17.9173 16.5833V14.25C17.9173 13 17.384 12.5 16.059 12.5H12.6923C11.3673 12.5 10.834 13 10.834 14.25V16.5833C10.834 17.8333 11.3673 18.3333 12.6923 18.3333H16.059C17.384 18.3333 17.9173 17.8333 17.9173 16.5833Z" fill="#071942"/>
    <path d="M9.16732 5.7474V3.41406C9.16732 2.16406 8.63399 1.66406 7.30899 1.66406H3.94232C2.61732 1.66406 2.08398 2.16406 2.08398 3.41406V5.7474C2.08398 6.9974 2.61732 7.4974 3.94232 7.4974H7.30899C8.63399 7.4974 9.16732 6.9974 9.16732 5.7474Z" fill="#071942"/>
    </svg>
  )
}

export default LeaderboardIcon