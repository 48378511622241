import axios from "axios";
let requestControllers = {};
const shouldCancelRequest = (url) => {
    // Define the API endpoints for which you want to apply request cancellation
    const endpointsToCancel = [
      process.env.REACT_APP_IS+`interaction/${localStorage.getItem('ci')}/filter`,
      
      // Add more endpoints as needed
    ];
  
    return endpointsToCancel.includes(url);
  };

axios.interceptors.request.use(
  (config) => {
    const apiEndpoint = config.url;
    if (shouldCancelRequest(apiEndpoint)&&requestControllers[apiEndpoint]) {
      requestControllers[apiEndpoint].abort();
    }
    const controller = new AbortController();
    requestControllers[apiEndpoint] = controller;

    if (config.url !== process.env.REACT_APP_IDENTITY + "oauth/token") {
      const token = localStorage.getItem("at");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Ascendo-Local-Time-Zone-Iana"] =
          localStorage.getItem("tz");
      }
    }
    return {
      ...config,
      signal: controller.signal,
    };
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const apiEndpoint = response.config.url;
    delete requestControllers[apiEndpoint];

    return response;
  },
  function (error) {
    const originalRequest = error.config;
    console.log(originalRequest);
    if (
      error.response.status === 401 &&
      originalRequest.url === process.env.REACT_APP_IDENTITY + "oauth/token"
    ) {
      console.log("401 error");
      return Promise.reject(error);
    }
    if (
      error.response.status === 400 &&
      originalRequest.url === process.env.REACT_APP_IDENTITY + "oauth/token"
    ) {
      // Redirect to the login page with the current window location as a redirect parameter
      localStorage.clear();
      if (localStorage.getItem("ci"))
        window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.href}`;
      else
        window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.href}`;
    }
    if (!(localStorage.getItem("at") && localStorage.getItem("rt"))) {
      if (localStorage.getItem("ci"))
        window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.href}`;
      else
        window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.href}`;
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      
      originalRequest._retry = true;
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("rt") },
      };
      return axios
        .get(process.env.REACT_APP_IDENTITY + "oauth/token", config)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("at", res.data.access_token);
            localStorage.setItem("rt", res.data.refresh_token);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("at");
            return axios(originalRequest);
          }
        });
    }

    return error.response;
  }
);
export default axios;
