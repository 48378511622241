

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetBusinessUnitsByUser } from '../actions/businessunitsActions';


export const getbusinessunitsbyUser = createAsyncThunk('buinfo',
    async ( thunkAPI) => {
        const response = await GetBusinessUnitsByUser()
        return response
    }
)

const BusinessUnitSlice = createSlice({
    name: 'buisnessunits',
    initialState: {
        bu_details: null,
        status: null,
        selected_bu: null

    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getbusinessunitsbyUser.pending, (state, action) => {
            state.status = false
        })
            .addCase(getbusinessunitsbyUser.fulfilled, (state, action) => {
                if(action.payload.status===200){
                state.bu_details = action.payload.data
                state.status = true
                }
                else{
                    state.status=false
                }

            })
            .addCase(getbusinessunitsbyUser.rejected, (state, action) => {
                state.status = false
            })
    },
})

export default BusinessUnitSlice.reducer