import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteCustomer } from "../../actions/identity/customerActions";
import { GetAllAvailability } from "../../actions/identity/availabilityActions";

export const deleteCustomer = createAsyncThunk(
  "deleteCustomer",
  async (thunkAPI) => {
    await DeleteCustomer();
  }
);

export const getAllAvailability = createAsyncThunk(
  "getAllAvailability",
  async (thunkAPI) => {
    const response = await GetAllAvailability();
    return response;
  }
);

const SharedComponentsSlice = createSlice({
  name: "SharedComponents",
  initialState: {
    checkedMultiBox: null,
    availabilities: [],
    notifications:[]
  },
  reducers: {
    updateMultiBox: (state, action) => {
      state.checkedMultiBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAvailability.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.availabilities = action.payload.data;
      })
  },
});

export const { updateMultiBox } = SharedComponentsSlice.actions;
export default SharedComponentsSlice.reducer;
