import React from 'react'

function ChatIcon() {
  return (
<svg width="24" height="24" viewBox="0 0 683 683" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.6666 36.6667C43.2 45.0667 15.8666 71.0667 3.73329 109.467L0.666626 119.333V284V448.667L3.73329 459.333C13.3333 492.933 38.6666 518.933 72.6666 530.133C82.2666 533.333 85.8666 533.733 108.4 534.4C126.133 534.8 133.333 535.467 133.333 536.533C133.333 537.467 128.8 560.4 123.333 587.733C116.4 622.267 113.733 638.267 114.533 640.4C116.667 645.867 120.8 648.4 126.267 647.6C129.333 647.2 173.2 624.667 236.667 591.067L342 535.333L470.667 534.667L599.333 534L610 530.4C644.533 518.533 669.333 492.933 678.933 459.333L682 448.667V284V119.333L678.933 109.467C667.867 74.4 644.8 50.4 610.933 38.8L600.667 35.3333L344.667 35.0667C134.133 34.9333 87.2 35.2 80.6666 36.6667ZM606.267 61.0667C631.867 70.6667 650.533 91.6 657.333 118.133C659.867 128.133 660 133.467 660 283.867C660 415.467 659.6 440.8 658 448.667C652.267 475.867 631.333 499.067 604 508.4C595.333 511.333 595.333 511.333 466 512L336.667 512.667L239.333 564.133C185.867 592.4 141.867 615.2 141.6 614.667C141.467 614.267 145.2 594.533 150 570.667C154.8 546.933 158.667 525.867 158.667 523.867C158.667 512.933 155.6 512 121.6 512C90.1333 512 79.8666 510.267 64.9333 502.4C52.6666 496 37.6 480.4 31.4666 468C22.4 449.333 22.6666 456.267 22.6666 283.867C22.6666 134.533 22.8 128 25.2 118.4C32.8 88.6667 53.7333 67.3333 82.6666 59.4667C89.0666 57.7333 123.2 57.4667 344 57.7333L598 58L606.267 61.0667Z" fill="black"/>
</svg>
    
  )
}

export default ChatIcon