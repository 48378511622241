import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import {
  getUserInfo
} from "../redux/slices/authenticationSlice";

const Authentication = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const customer_uuid = params.customer_uuid;
  const access_token = params.access_token;
  const refresh_token = params.refresh_token;
  localStorage.setItem("tz", timeZone);
  localStorage.setItem("ci", customer_uuid);
  localStorage.setItem("at", access_token);
  localStorage.setItem("rt", refresh_token);
  const { userdetails } = useSelector((state) => state.authenticationReducer);
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (userdetails) {
      navigate(`/${customer_uuid}/home`);
      } 
    }
  , [userdetails]);

  return <></>;
};

export default Authentication;
