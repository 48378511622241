import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import "./logout.scss";

function Logout() {
    const closeTab = () => {
       
        window.close();
      };
      localStorage.clear()
      


  return (
    <Grid className="mainFrame">
        <Grid className="innerwrap" gap="20px">
            <Typography >You have been succesfully logged out.</Typography>
            <Button variant='ascendo' onClick={()=>window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.origin}`}>Close the tab</Button>
        </Grid>
    </Grid>
  )
}

export default Logout