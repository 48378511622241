import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { useEffect, useState } from "react";
import { addDays, isAfter } from "date-fns";

import React from "react";

function MyDateRangePicker({ onStateChange, reset, resetState }) {
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    onStateChange(state[0]);
  }, [state]);

  useEffect(() => {
    if (reset) {
      setState([
        {
          startDate: addDays(new Date(), -7),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      resetState();
    }
  }, [reset]);

  const isDateDisabled = (date) => {
    return isAfter(date, new Date());
  };
  return (
    <DateRangePicker
      editableDateInputs={true}
      onChange={(item) => setState([item.selection])}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
      preventSnapRefocus={true}
      calendarFocus="backwards"
      disabledDay={(date) => isDateDisabled(date)}
    />
  );
}

export default MyDateRangePicker;
