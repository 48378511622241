import React from 'react'

function DowloadIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8778 14.9911C11.6878 14.9911 11.4978 14.9211 11.3478 14.7711L8.78781 12.2111C8.49781 11.9211 8.49781 11.4411 8.78781 11.1511C9.07781 10.8611 9.55781 10.8611 9.84781 11.1511L11.8778 13.1811L13.9078 11.1511C14.1978 10.8611 14.6778 10.8611 14.9678 11.1511C15.2578 11.4411 15.2578 11.9211 14.9678 12.2111L12.4078 14.7711C12.2578 14.9211 12.0678 14.9911 11.8778 14.9911Z" fill="#071942"/>
<path d="M11.8828 14.92C11.4728 14.92 11.1328 14.58 11.1328 14.17V4C11.1328 3.59 11.4728 3.25 11.8828 3.25C12.2928 3.25 12.6328 3.59 12.6328 4V14.17C12.6328 14.58 12.2928 14.92 11.8828 14.92Z" fill="#071942"/>
<path d="M12 20.9297C6.85 20.9297 3.25 17.3297 3.25 12.1797C3.25 11.7697 3.59 11.4297 4 11.4297C4.41 11.4297 4.75 11.7697 4.75 12.1797C4.75 16.4497 7.73 19.4297 12 19.4297C16.27 19.4297 19.25 16.4497 19.25 12.1797C19.25 11.7697 19.59 11.4297 20 11.4297C20.41 11.4297 20.75 11.7697 20.75 12.1797C20.75 17.3297 17.15 20.9297 12 20.9297Z" fill="#071942"/>
</svg>

  )
}

export default DowloadIcon