import React from 'react'

function UpdateIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3334 9.99984C18.3334 14.5998 14.6001 18.3332 10.0001 18.3332C5.40008 18.3332 2.59175 13.6998 2.59175 13.6998M2.59175 13.6998H6.35842M2.59175 13.6998V17.8665M1.66675 9.99984C1.66675 5.39984 5.36675 1.6665 10.0001 1.6665C15.5584 1.6665 18.3334 6.29984 18.3334 6.29984M18.3334 6.29984V2.13317M18.3334 6.29984H14.6334" stroke="#071942" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default UpdateIcon