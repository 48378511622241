import { useTheme } from "@emotion/react";
import {
  Analytics,
  LegendToggle,
  PrivacyTip,
  Settings,
} from "@mui/icons-material";
import { Card, Divider, Grid, Tooltip } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentRoutingIcon from "../../assets/AgentRoutingIcon";
import AiresolveIcon from "../../assets/airesolveIcon";
import SparesLargeIcon from "../../assets/SparesLargeIcon";
import VocLargeIcon from "../../assets/VocLargeIcon";
import { getUserInfo } from "../../redux/slices/authenticationSlice";
import {
  getAllModule,
  getInteractionsHomePage
} from "../../redux/slices/leaderboardSlice";
import "./homeview.scss";
function transform(value) {
  if (value) {
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 29) {
      return "Just now";
    }

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter;
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return counter + " " + i + " ago"; // singular (1 day ago)
        } else {
          return counter + " " + i + "s ago"; // plural (2 days ago)
        }
      }
    }
  }
  return value;
}
function HomeView() {
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const [interactionsPagination, setInteractionsPagination] = useState({
    page: 0,
    pageSize: 5,
  });
  const { userdetails } = useSelector((state) => state.authenticationReducer);
  const { moduledetails, home_page_interactions_details } = useSelector(
    (state) => state.leaderboardReducer
  );
  const userDetails = JSON.parse(localStorage.getItem("ud"));
  useEffect(() => {
    if (!localStorage.getItem("ci"))
      window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.origin}`;
    else {
      dispatch(getUserInfo());
      dispatch(getAllModule(localStorage.getItem("ci")));
    }
  }, []);

  useEffect(() => {

    dispatch(getInteractionsHomePage());
  }, [interactionsPagination.page, interactionsPagination.pageSize]);





  return (
    <div
      className="homeWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Home
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Welcome to Ascendo! Support beyond Expectations.
            </Typography>
          </div>
        </div>
        <Grid>
          <Typography
            sx={{
              color: colors.ascendo.blue,
              mt: "10px", mb: '5px',
              fontWeight: "600",
              fontSize: "24px",
            }}
          >
            Greetings, {userDetails?.username ?? userdetails?.username}
          </Typography>
          <Typography
            sx={{
              color: colors.ascendo.darkblue,
              mb: "16px",
              fontWeight: "600",
            }}
          >
            Your apps
          </Typography>
          <Grid container spacing={3}>
            {(userdetails?.permissions?.includes("super_admin") ||
              userdetails?.permissions?.includes("admin")) && (
                <Grid
                  item
                  md={1.8}
                  sm={4}
                  xs={6}
                  xl={1.3}
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_ADMIN +
                      `${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "ui"
                      )}/${localStorage.getItem("at")}/${localStorage.getItem(
                        "rt"
                      )}`
                    )
                  }
                >
                  <Card
                    sx={{
                      p: "8px",
                      cursor: "pointer",
                      transition:
                        "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                      "&:hover": {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f5f5f5",
                        height: "100px",
                        borderRadius: "6px",
                      }}
                    >
                      <Settings
                        sx={{
                          color: "#071942",
                          opacity: "0.8",
                          fontSize: "44px",
                        }}
                      />
                    </Grid>
                    <Typography
                      sx={{
                        color: colors.ascendo.darkblue,
                        fontSize: "12px",
                        mt: "8px",
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      Admin
                    </Typography>
                  </Card>
                </Grid>
              )}
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Resolution Prediction"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                  )
                    window.open(
                      `https://webbot.ascendo.ai`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <AiresolveIcon size={"40"} />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Web bot
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "Agent Routing")
                  ?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find((item) => item.name === "Agent Routing")
                      ?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${process.env.REACT_APP_AGENT_ROUTING
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Agent Routing"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Agent Routing"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Agent Routing"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <AgentRoutingIcon size="40" />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Agent Routing
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "next_gen_spares")
                  ?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${process.env.REACT_APP_SPARES
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <SparesLargeIcon />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Cognitive Spares
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "log")?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                  )
                    window.open(
                      `${process.env.REACT_APP_LOG
                      }validate-user/${localStorage.getItem(
                        "ci"
                      )}/${localStorage.getItem("li")}/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "log"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <LegendToggle
                      sx={{
                        color: colors.ascendo.darkblue,
                        opacity: 0.8,
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Log Analysis
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "Privacy Filter")
                  ?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Privacy Filter"
                    )?.is_active
                  )
                    window.open(
                      `https://${process.env.REACT_APP_PRIVACY_FILTER
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Privacy Filter"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Privacy Filter"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Privacy Filter"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <PrivacyTip
                      sx={{
                        color: "#071942",
                        opacity: "0.8",
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Privacy Filter
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Resolution Prediction"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${process.env.REACT_APP_RP
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <AiresolveIcon size={"40"} />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Resolution Prediction
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Root Cause Analysis"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${process.env.REACT_APP_RCA
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <Analytics
                      sx={{
                        color: "#071942",
                        opacity: "0.8",
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Root Cause Analysis
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Voice of the Customer"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${process.env.REACT_APP_VOC
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                      ? "pointer"
                      : "default",
                    opacity: moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                      ? {
                        // Light grey background on hover
                        boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                        transform: "scale(1.05)", // Slight zoom effect
                      }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <VocLargeIcon />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Voice of the Customer
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
          </Grid>
          <Divider sx={{ mt: "18px" }} />
          <div>

            {home_page_interactions_details?.length > 0 &&
              <Typography sx={{
                mt: "18px",
                color: colors.ascendo.darkblue,
                fontWeight: "600",
              }}>Recent Interactions</Typography>}

            <Grid container spacing={2}>
              {/* Auto Solved Card */}
              {home_page_interactions_details?.map((item) => (
                <Grid item className="cardGrid" key={item.uuid}>
                  <Card className="commonCard highlightWrap">

                    <div className="highinnerText">
                      <Typography className="highlightGray">{item.label}</Typography>
                      <Typography className="highlightTitle">{item.problem} </Typography>
                    </div>

                    <div className="highlightFooter">
                      <div className="highlightProduct">
                        <Typography className="pgText">{item.product_family}</Typography>
                      </div>
                      <div className="highOuterText">
                        <Typography className="emailText">{item.created_by}</Typography>
                        <Typography className="timeText">{transform(item.created_at)}</Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}




            </Grid>
          </div>


        </Grid>
      </Container>
    </div>
  );
}

export default HomeView;
