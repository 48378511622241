import axios from "../../../utils/axios";
export const GetInteractionsHomePage = async (page_no,page_size) => {
  let response = await axios(
    process.env.REACT_APP_IS +
      `home/${localStorage.getItem(
        "ci"
      )}/interaction`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
       
      },
    }
  );
  return { data: response.data, status: response.status };
};
