import axios from "../../../utils/axios";

export const GetAllAvailability= async () => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY +
      `availability/${localStorage.getItem("ci")}/get_all`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    }
  );
  return { data: response.data, status: response.status };
};


