import React from 'react'

function VocLargeIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.491 1.66406H6.50768C3.47435 1.66406 1.66602 3.4724 1.66602 6.50573V13.4807C1.66602 16.5224 3.47435 18.3307 6.50768 18.3307H13.4827C16.516 18.3307 18.3243 16.5224 18.3243 13.4891V6.50573C18.3327 3.4724 16.5243 1.66406 13.491 1.66406ZM6.35768 15.1224C6.35768 15.4641 6.07435 15.7474 5.73268 15.7474C5.39102 15.7474 5.10768 15.4641 5.10768 15.1224V13.3974C5.10768 13.0557 5.39102 12.7724 5.73268 12.7724C6.07435 12.7724 6.35768 13.0557 6.35768 13.3974V15.1224ZM10.6243 15.1224C10.6243 15.4641 10.341 15.7474 9.99935 15.7474C9.65768 15.7474 9.37435 15.4641 9.37435 15.1224V11.6641C9.37435 11.3224 9.65768 11.0391 9.99935 11.0391C10.341 11.0391 10.6243 11.3224 10.6243 11.6641V15.1224ZM14.891 15.1224C14.891 15.4641 14.6077 15.7474 14.266 15.7474C13.9243 15.7474 13.641 15.4641 13.641 15.1224V9.93906C13.641 9.5974 13.9243 9.31406 14.266 9.31406C14.6077 9.31406 14.891 9.5974 14.891 9.93906V15.1224ZM14.891 7.30573C14.891 7.6474 14.6077 7.93073 14.266 7.93073C13.9243 7.93073 13.641 7.6474 13.641 7.30573V6.4974C11.516 8.68073 8.85768 10.2224 5.88268 10.9641C5.83268 10.9807 5.78268 10.9807 5.73268 10.9807C5.44935 10.9807 5.19935 10.7891 5.12435 10.5057C5.04102 10.1724 5.24102 9.83073 5.58268 9.7474C8.39102 9.0474 10.891 7.5724 12.8743 5.48906H11.8327C11.491 5.48906 11.2077 5.20573 11.2077 4.86406C11.2077 4.5224 11.491 4.23906 11.8327 4.23906H14.2743C14.3077 4.23906 14.3327 4.25573 14.366 4.25573C14.4077 4.26406 14.4493 4.26406 14.491 4.28073C14.5327 4.2974 14.566 4.3224 14.6077 4.3474C14.6327 4.36406 14.6577 4.3724 14.6827 4.38906C14.691 4.3974 14.691 4.40573 14.6993 4.40573C14.7327 4.43906 14.7577 4.4724 14.7827 4.50573C14.8077 4.53906 14.8327 4.56406 14.841 4.5974C14.8577 4.63073 14.8577 4.66406 14.866 4.70573C14.8743 4.7474 14.891 4.78906 14.891 4.83906C14.891 4.8474 14.8993 4.85573 14.8993 4.86406V7.30573H14.891Z" fill="#071942" fill-opacity="0.8"/>
    </svg>
    
  )
}

export default VocLargeIcon