import React from 'react'

function AgentRoutingIcon({size}) {
  return (
    
    <svg width={size??"24"} height={size??"24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0009 2C16.6209 2 18.7509 4.13 18.7509 6.75C18.7509 9.32 16.7409 11.4 14.1209 11.49C14.0409 11.48 13.9609 11.48 13.9009 11.49C13.8809 11.49 13.8709 11.49 13.8509 11.49C13.8409 11.49 13.8409 11.49 13.8309 11.49C11.2709 11.4 9.2609 9.32 9.2509 6.75C9.2509 4.13 11.3809 2 14.0009 2Z" fill="#071942" fill-opacity="0.8"/>
    <path d="M3.01091 7.34001C2.85091 9.28001 4.23091 10.98 6.14091 11.21C6.15091 11.21 6.15091 11.21 6.16091 11.21H6.19091C6.25091 11.21 6.31091 11.21 6.36091 11.23C7.33091 11.28 8.22091 10.97 8.89091 10.4C7.86091 9.48001 7.27091 8.10001 7.39091 6.60001C7.46091 5.79001 7.74091 5.05001 8.16091 4.42001C7.78091 4.23001 7.34091 4.11001 6.89091 4.07001C4.93091 3.90001 3.18091 5.36001 3.01091 7.34001Z" fill="#071942" fill-opacity="0.8"/>
    <path d="M1.01091 16.59C1.09091 17.56 1.71091 18.4 2.75091 18.97C3.75091 19.52 5.01091 19.78 6.26091 19.75C5.54091 19.1 5.12091 18.29 5.04091 17.43C4.94091 16.19 5.53091 15 6.71091 14.05C7.38091 13.52 8.16091 13.1 9.01091 12.79C6.80091 12.15 4.02091 12.58 2.31091 13.96C1.39091 14.7 0.920906 15.63 1.01091 16.59Z" fill="#071942" fill-opacity="0.8"/>
    <path d="M19.0576 14.141C16.2466 12.2899 11.7066 12.2928 8.92098 14.1499C7.66098 14.9899 6.97098 16.1299 6.96098 17.3599C6.96098 18.5999 7.66098 19.7399 8.92098 20.5899C10.321 21.5299 12.161 21.9999 14.001 21.9999C14.844 21.9999 15.6871 21.9012 16.4878 21.7039C15.0288 21.1072 14.0009 19.6737 14.0009 18C14.0009 15.7909 15.7918 14 18.0009 14C18.3667 14 18.721 14.0491 19.0576 14.141Z" fill="#071942" fill-opacity="0.8"/>
    <path d="M20.2284 15.9975C19.6884 15.3825 18.8859 15 18.0009 15C17.1609 15 16.3959 15.345 15.8484 15.9075C15.5334 16.23 15.2934 16.62 15.1509 17.055C15.0534 17.355 15.0009 17.67 15.0009 18C15.0009 18.5625 15.1584 19.095 15.4359 19.545C15.5859 19.8 15.7809 20.0325 16.0059 20.2275C16.5309 20.7075 17.2284 21 18.0009 21C18.3309 21 18.6459 20.9475 18.9384 20.8425C19.6284 20.625 20.2059 20.1525 20.5659 19.545C20.7234 19.29 20.8434 18.9975 20.9109 18.6975C20.9709 18.4725 21.0009 18.24 21.0009 18C21.0009 17.235 20.7084 16.53 20.2284 15.9975ZM19.1184 18.5475H18.5634V19.1325C18.5634 19.44 18.3084 19.695 18.0009 19.695C17.6934 19.695 17.4384 19.44 17.4384 19.1325V18.5475H16.8834C16.5759 18.5475 16.3209 18.2925 16.3209 17.985C16.3209 17.6775 16.5759 17.4225 16.8834 17.4225H17.4384V16.89C17.4384 16.5825 17.6934 16.3275 18.0009 16.3275C18.3084 16.3275 18.5634 16.5825 18.5634 16.89V17.4225H19.1184C19.4259 17.4225 19.6809 17.6775 19.6809 17.985C19.6809 18.2925 19.4334 18.5475 19.1184 18.5475Z" fill="#071942" fill-opacity="0.8"/>
    </svg>
    
  )
}

export default AgentRoutingIcon