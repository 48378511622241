import React from "react";

function LogoutIcon({ opacity }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0996 16.4398C14.7896 20.0398 12.9396 21.5098 8.88961 21.5098H8.75961C4.28961 21.5098 2.49961 19.7198 2.49961 15.2498L2.49961 8.72976C2.49961 4.25977 4.28961 2.46976 8.75961 2.46976H8.88961C12.9096 2.46976 14.7596 3.91976 15.0896 7.45976"
        stroke="#071942"
        strokeOpacity={opacity}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99988 12L20.3799 12"
        stroke="#071942"
        strokeOpacity={opacity}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.15 15.3496L21.5 11.9996L18.15 8.64961"
        stroke="#071942"
        strokeOpacity={opacity}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LogoutIcon;
