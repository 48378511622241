import axios from "../../../utils/axios";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

export const GetInteractionsCount = async (
 start_date,end_date
) => {

  let response = await axios(
    process.env.REACT_APP_ANALYTICS +
      `leaderboard/${localStorage.getItem("ci")}/source_channel_total_count`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data: {
  
        
        start_date,
        end_date,
      
        timezone:timeZone
        ,
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetInteractionsCountTable = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_ANALYTICS +
       `leaderboard/${localStorage.getItem("ci")}/count/table`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
         timezone:timeZone
         ,
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetSourceChannelTotalCountPieChart = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_ANALYTICS +
       `leaderboard/${localStorage.getItem("ci")}/source_channel_total_count/pie_chart`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
         timezone:timeZone
         ,
       },
     }
   );
   return { data: response.data, status: response.status };
 };
 export const GetSourceChannelTotalCountBarChart = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_ANALYTICS +
       `leaderboard/${localStorage.getItem("ci")}/source_channel_count/bar_chart`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
         timezone:timeZone
         ,
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetEndChannelTotalCountBarChart = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_ANALYTICS +
       `leaderboard/${localStorage.getItem("ci")}/end_channel_count/bar_chart`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
         timezone:timeZone
         ,
       },
     }
   );
   return { data: response.data, status: response.status };
 };


 export const GetEndChannelTotalCountPieChart = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_ANALYTICS +
       `leaderboard/${localStorage.getItem("ci")}/end_channel_total_count/pie_chart`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
         timezone:timeZone
         ,
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetInteractionsCountByHowSolved = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/how_solved/count`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetInteractionsCountByWorkload = async (
  start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/workload_type/count`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetInteractionsTableByHowSolved = async (
  page_no,page_size,how_solved,start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/how_solved/table?page_no=${page_no}&page_size=${page_size}`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
         how_solved
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };
 export const GetInteractionsTableByWorkload = async (
  page_no,page_size,workload_type,start_date,end_date,source_channel_names
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/workload_type/table?page_no=${page_no}&page_size=${page_size}`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
         workload_type,
         source_channel_names
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };
 export const GetInteractionsSourceChannelByWorkload = async (
workload_type,start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/workload_type/source_channel/count`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
         workload_type,
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetSavingsChart = async (
 start_date,end_date
 ) => {
 
   let response = await axios(
     process.env.REACT_APP_IS +
       `leaderboard/${localStorage.getItem("ci")}/interaction/how_solved/savings_chart`,
     {
       method: "POST",
       headers: {
         accept: "application/json,text/plain",
         "Content-Type": "application/json",
       },
       data: {
   
         
         start_date,
         end_date,
       
       },
     }
   );
   return { data: response.data, status: response.status };
 };

 export const GetCountChart = async (
  start_date,end_date
  ) => {
  
    let response = await axios(
      process.env.REACT_APP_IS +
        `leaderboard/${localStorage.getItem("ci")}/interaction/how_solved/count_chart`,
      {
        method: "POST",
        headers: {
          accept: "application/json,text/plain",
          "Content-Type": "application/json",
        },
        data: {
    
          
          start_date,
          end_date,
        
        },
      }
    );
    return { data: response.data, status: response.status };
  };

  export const ExportInteractionsTableByHowSolved = async (
   how_solved,start_date,end_date
  ) => {
    try {
      const response = await axios(
        process.env.REACT_APP_IS +
          `leaderboard/${localStorage.getItem("ci")}/interaction/how_solved/table/export`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "blob",
          data: {
            how_solved,start_date,end_date
          },
        }
      );
  
      if (response.headers["content-disposition"]) {
        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch = contentDisposition.match(
          /filename\*=utf-8''(.+?)(;|$)/
        );
        if (filenameMatch) {
          const filename = decodeURIComponent(filenameMatch[1]);
          return { response, filename };
        }
      }
  
      // If content-disposition header or filename not found, use a default filename
      return { response, filename: "default_filename.xlsx" };
    } catch (error) {
      console.error("Error in DownloadTopIssues:", error);
      throw error; // Rethrow the error for higher-level error handling
    }
  };
  export const ExportInteractionsTableByWorkload = async (
    workload_type,start_date,end_date,source_channel_names
   ) => {
     try {
       const response = await axios(
         process.env.REACT_APP_IS +
           `leaderboard/${localStorage.getItem("ci")}/interaction/workload_type/table/export`,
         {
           method: "POST",
           headers: {
             "Access-Control-Allow-Origin": "*",
           },
           responseType: "blob",
           data: {
             workload_type,start_date,end_date,source_channel_names
           },
         }
       );
   
       if (response.headers["content-disposition"]) {
         const contentDisposition = response.headers["content-disposition"];
         const filenameMatch = contentDisposition.match(
           /filename\*=utf-8''(.+?)(;|$)/
         );
         if (filenameMatch) {
           const filename = decodeURIComponent(filenameMatch[1]);
           return { response, filename };
         }
       }
   
       // If content-disposition header or filename not found, use a default filename
       return { response, filename: "default_filename.xlsx" };
     } catch (error) {
       console.error("Error in DownloadTopIssues:", error);
       throw error; // Rethrow the error for higher-level error handling
     }
   };
