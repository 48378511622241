function AscendoIcon() {
  return (
    <svg
      width="100"
      height="21"
      viewBox="0 0 100 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5044 20.002H12.3761L11.4831 14.6229H5.5798L3.38522 20.002H0L8.74686 0.00195312H11.9823L15.5025 20.002H15.5044ZM11.13 12.2094L9.88389 3.77843H9.81549L6.55228 12.2094H11.13Z"
        fill="white"
      />
      <path
        d="M26.0941 10.3227C25.1734 9.77065 24.4597 9.40259 23.955 9.22047C23.4484 9.03836 22.9806 8.94634 22.5462 8.94634C22.0229 8.94634 21.6402 9.03452 21.4017 9.2128C21.1632 9.39108 21.0431 9.70355 21.0431 10.154C21.0431 10.3419 21.1392 10.5451 21.3333 10.7656C21.5274 10.986 21.8233 11.2352 22.2208 11.517L22.9381 12.0231C23.7498 12.6231 24.3118 13.1024 24.6243 13.4628C24.9349 13.8232 25.1844 14.2123 25.3693 14.6283C25.5542 15.0443 25.6467 15.5005 25.6467 15.997C25.6467 16.7562 25.4692 17.4348 25.1123 18.0329C24.7555 18.6329 24.2508 19.1007 23.5963 19.4361C22.9418 19.7735 22.2134 19.9422 21.4091 19.9422C20.6049 19.9422 19.8875 19.8406 19.148 19.6413C18.4085 19.44 17.7133 19.0777 17.0625 18.5524L17.5506 15.8839C18.2365 16.3421 18.8799 16.7006 19.4808 16.9575C20.0817 17.2143 20.6529 17.3428 21.1947 17.3428C21.6273 17.3428 21.9749 17.2373 22.2374 17.0265C22.4999 16.8156 22.6294 16.5472 22.6294 16.2194C22.6294 15.9395 22.5092 15.6462 22.2707 15.3414C22.0322 15.0366 21.6717 14.7069 21.1947 14.3523C20.1112 13.5471 19.4031 12.9644 19.0685 12.604C18.7339 12.2436 18.4824 11.8698 18.3105 11.4806C18.1385 11.0915 18.0535 10.687 18.0535 10.2652C18.0535 9.49844 18.231 8.81215 18.5878 8.2083C18.9446 7.60445 19.4494 7.1367 20.1038 6.80506C20.7583 6.47342 21.5016 6.30664 22.3317 6.30664C23.0084 6.30664 23.6573 6.42358 24.2748 6.65745C24.8923 6.89132 25.5006 7.19613 26.0959 7.56994V10.3227H26.0941Z"
        fill="white"
      />
      <path
        d="M38.3357 9.41018C37.9659 9.27983 37.5961 9.18398 37.2245 9.12263C36.8547 9.06129 36.4073 9.03062 35.8841 9.03062C34.9985 9.03062 34.185 9.2434 33.4399 9.66898C32.6948 10.0945 32.0995 10.6831 31.6521 11.4307C31.2047 12.1802 30.9828 12.9796 30.9828 13.8308C30.9828 14.935 31.2768 15.7919 31.8629 16.3995C32.449 17.0072 33.2754 17.312 34.3403 17.312C34.9541 17.312 35.5106 17.2373 36.0117 17.0877C36.5127 16.9382 37.1099 16.6909 37.8069 16.3439L37.4685 19.2367C36.2945 19.7236 35.0762 19.9671 33.8115 19.9671C32.656 19.9671 31.6299 19.714 30.7314 19.2079C29.8328 18.7019 29.1377 17.9791 28.6459 17.0379C28.1541 16.0967 27.9082 15.0289 27.9082 13.8289C27.9082 12.4525 28.2576 11.1853 28.9583 10.0236C29.6572 8.86384 30.6278 7.95327 31.8703 7.2919C33.1108 6.63246 34.4808 6.30273 35.9802 6.30273C36.4221 6.30273 36.8917 6.33341 37.389 6.39475C37.8845 6.45609 38.3283 6.53853 38.7165 6.64013L38.3375 9.40635L38.3357 9.41018Z"
        fill="white"
      />
      <path
        d="M46.1618 6.30859C47.101 6.30859 47.9385 6.5348 48.6744 6.98913C49.4102 7.44345 49.9796 8.08756 50.3808 8.91954C50.782 9.75343 50.9836 10.6928 50.9836 11.7414C50.9836 12.2091 50.9373 12.7056 50.8486 13.2289H42.8857C42.832 13.5567 42.8043 13.8558 42.8043 14.128C42.8043 14.82 42.9374 15.4258 43.2036 15.9453C43.4699 16.4648 43.8415 16.8636 44.3203 17.1396C44.7992 17.4157 45.3354 17.5537 45.9325 17.5537C46.5297 17.5537 47.1657 17.4252 47.8683 17.1684C48.5727 16.9115 49.3714 16.5204 50.2644 15.9952L49.7633 18.7326C48.3194 19.5569 46.9013 19.9691 45.511 19.9691C44.3832 19.9691 43.3867 19.7122 42.5251 19.1965C41.6636 18.6828 40.9998 17.9428 40.5339 16.9786C40.068 16.0143 39.8369 14.9101 39.8369 13.6641C39.8369 12.326 40.1161 11.0877 40.6763 9.95088C41.2365 8.81411 42.0019 7.9227 42.9707 7.27668C43.9413 6.63065 45.0044 6.30859 46.1599 6.30859H46.1618ZM48.0975 11.3062V11.0819C48.0975 10.3324 47.92 9.7381 47.5632 9.2991C47.2064 8.86011 46.698 8.63966 46.0398 8.63966C44.74 8.63966 43.8064 9.52914 43.2369 11.3081H48.0975V11.3062Z"
        fill="white"
      />
      <path
        d="M63.469 20.0012H60.5035L61.4649 12.7837C61.537 12.2508 61.585 11.8674 61.6072 11.6316C61.6294 11.3977 61.6405 11.1409 61.6405 10.8591C61.6405 10.3817 61.5259 9.98874 61.2948 9.68011C61.0637 9.37147 60.7549 9.21619 60.3667 9.21619C59.9784 9.21619 59.4681 9.40981 58.8636 9.79896C58.259 10.1881 57.5915 10.8169 56.8594 11.6872L55.7353 19.9993H52.7568L54.504 7.02507H57.4825L57.1977 9.10309C57.9299 8.34396 58.6417 7.77078 59.3369 7.38355C60.032 6.9944 60.7401 6.80078 61.463 6.80078C62.1859 6.80078 62.7332 6.95989 63.2157 7.27811C63.6983 7.59633 64.0643 8.03149 64.3121 8.58359C64.5598 9.13568 64.6837 9.76829 64.6837 10.4795C64.6837 10.6482 64.6818 10.8073 64.6763 10.9568C64.6708 11.1063 64.6634 11.2655 64.6486 11.4342C64.6338 11.6029 64.6153 11.7946 64.5876 12.0093C64.5598 12.224 64.5284 12.4923 64.4933 12.8086L63.4635 19.9974L63.469 20.0012Z"
        fill="white"
      />
      <path
        d="M80.1916 0.00191699L77.5237 19.5054C76.2961 19.7949 74.9465 19.9406 73.4748 19.9406C72.1658 19.9406 71.014 19.7009 70.0212 19.2236C69.0283 18.7463 68.2648 18.06 67.7323 17.1667C67.1998 16.2734 66.9336 15.2497 66.9336 14.0976C66.9336 12.5161 67.2849 11.1397 67.9893 9.96837C68.6937 8.799 69.6865 7.90377 70.9678 7.28649C72.249 6.66922 73.7207 6.36059 75.3828 6.36059H76.3312L77.1836 0H80.1898L80.1916 0.00191699ZM75.967 8.94661H75.4124C74.2846 8.94661 73.3176 9.14981 72.5152 9.55813C71.711 9.96453 71.0935 10.5473 70.6609 11.3064C70.2282 12.0656 70.0101 12.9493 70.0101 13.9596C70.0101 14.9698 70.3299 15.8593 70.9715 16.4861C71.613 17.113 72.4746 17.4274 73.558 17.4274C74.1182 17.4274 74.5508 17.391 74.8577 17.3143L75.9689 8.94469L75.967 8.94661Z"
        fill="white"
      />
      <path
        d="M93.056 12.2053C93.056 13.6373 92.7768 14.9504 92.2166 16.1428C91.6564 17.3371 90.8781 18.2726 89.8815 18.9512C88.8832 19.6298 87.7887 19.9691 86.598 19.9691C85.5423 19.9691 84.5976 19.7161 83.7674 19.21C82.9373 18.7039 82.2958 17.9908 81.8446 17.0687C81.3935 16.1466 81.168 15.1095 81.168 13.9593C81.168 12.5657 81.4582 11.2736 82.0406 10.0851C82.623 8.89654 83.4199 7.97063 84.4312 7.30543C85.4425 6.64023 86.5352 6.30859 87.7073 6.30859C88.7445 6.30859 89.6708 6.55205 90.4824 7.03897C91.2941 7.52588 91.9264 8.22175 92.3775 9.12466C92.8286 10.0276 93.0542 11.0551 93.0542 12.2072L93.056 12.2053ZM89.9814 12.05C89.9814 11.0953 89.7688 10.3151 89.3454 9.71318C88.9202 9.10932 88.347 8.80835 87.626 8.80835C86.9752 8.80835 86.3928 9.05373 85.8788 9.5464C85.3648 10.0391 84.9581 10.7119 84.6604 11.5688C84.3628 12.4257 84.213 13.298 84.213 14.1874C84.213 14.7951 84.3165 15.3453 84.5236 15.838C84.7307 16.3287 85.0136 16.7083 85.3704 16.9748C85.7272 17.2412 86.1173 17.3754 86.5425 17.3754C87.1841 17.3754 87.7683 17.1415 88.2952 16.6738C88.824 16.206 89.2363 15.5619 89.534 14.7434C89.8316 13.9248 89.9814 13.0277 89.9814 12.0538V12.05Z"
        fill="white"
      />
      <path
        d="M80.8886 3.89537L83.3013 6.15934C88.4965 3.40653 92.1461 5.43471 93.322 6.79386L100 0.00195312L80.8867 3.89537H80.8886Z"
        fill="url(#paint0_linear_6212_60714)"
      />
      <path
        d="M96.0104 18.0217L100 0.00195312L93.3203 6.79386C95.844 10.1256 94.62 14.2491 93.6919 15.9398L96.0122 18.0217H96.0104Z"
        fill="url(#paint1_linear_6212_60714)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6212_60714"
          x1="90.2585"
          y1="0.515708"
          x2="91.0902"
          y2="6.4907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#314795" />
          <stop offset="1" stopColor="#3E70B7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6212_60714"
          x1="93.3998"
          y1="4.45896"
          x2="101.548"
          y2="12.1828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#314795" />
          <stop offset="1" stopColor="#3E70B7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AscendoIcon;
