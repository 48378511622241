import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';

// Initialize the "no data" module
noDataToDisplay(Highcharts);

function Barcharts({ value }) {
  useEffect(() => {
    const chart = Highcharts.chart('barchart', {
      chart: {
        type: 'column', // Ensures the chart is a column chart
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '', // Keep empty if no title is required
        align: 'left',
      },
      xAxis: {
        categories: value?.x_axis?.length
          ? value?.x_axis.map(timestamp => new Date(timestamp).toLocaleDateString()) 
          : [], // Use readable dates or empty array if no data
        title: {
          text: value?.x_title || 'X-Axis', // Use x_title from the passed value or default
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: value?.y_title || 'Y-Axis', // Use y_title from the passed value or default
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: value?.y_axis?.length 
        ? value?.y_axis.map(dataSet => ({
          name: dataSet.name,
          type: 'column', // Explicitly set each series as a column chart
          data: dataSet.data
        }))
        : [], // If no series, pass an empty array
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });

    // Show "No data" message if no data is provided
    if (!value?.x_axis?.length || !value?.y_axis?.length) {
      chart.showNoData('No data available'); // Display the message
    }

    return () => {
      chart.destroy(); // Clean up the chart instance on unmount
    };
  }, [value]);

  return <div id="barchart" style={{ height: "300px" }} />;
}

export default Barcharts;
