import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "../slices/authenticationSlice";
import businessunitsReducer from "../slices/businessunitsSlice";
import dataTableReducer from "../slices/dataTableSlice";
import datasourceReducer from "../slices/datasourceSlice";
import leaderboardSlice from "../slices/leaderboardSlice";
import sharedComponentsSlice from "../slices/sharedComponents/sharedComponentsSlice";


const store = configureStore({
    reducer:{
        authenticationReducer:authenticationSlice,
        leaderboardReducer:leaderboardSlice,
        sharedComponentReducer:sharedComponentsSlice,
        businessunitsReducer: businessunitsReducer,
        datasourceReducer: datasourceReducer,
        dataTableReducer: dataTableReducer,
      
    }
})

export default store