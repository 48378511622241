import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetCustomerEffortChart } from "../actions/analytics/customerEffortActions";
import { GetCustomerFeedbackChart } from "../actions/analytics/customerFeedbackActions";
import { GetInteractionsHomePage } from "../actions/analytics/homeActions";
import {
  ExportInteractionsTableByHowSolved,
  ExportInteractionsTableByWorkload,
  GetCountChart,
  GetEndChannelTotalCountBarChart,
  GetEndChannelTotalCountPieChart,
  GetInteractionsCount,
  GetInteractionsCountByHowSolved,
  GetInteractionsCountByWorkload,
  GetInteractionsSourceChannelByWorkload,
  GetInteractionsTableByHowSolved,
  GetInteractionsTableByWorkload,
  GetSavingsChart,
  GetSourceChannelTotalCountBarChart,
  GetSourceChannelTotalCountPieChart
} from "../actions/analytics/leaderboardActions";
import { GetHighlevelStats } from "../actions/analytics/usageAnalyticsActions";
import { GetActiveChannels } from "../actions/dataIntegration/channelActions";
import { GetAllModules } from "../actions/identity/moduleActions";
import { GetAllBuHavingPgUnderUser } from "../actions/identity/organizationActions";

const downloadFile = async (blob, filename) => {
  // Create a temporary link element
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  // Trigger the download
  downloadLink.click();

  // Cleanup the temporary link after download
  URL.revokeObjectURL(downloadLink.href);
};

export const getActiveChannels = createAsyncThunk(
  "getActiveChannels",
  async (thunkAPI) => {
    const response = await GetActiveChannels();
    return response;
  }
);
export const getAllModule = createAsyncThunk(
  "getAllModules",
  async (customer_uuid, thunkAPI) => {
    const response = await GetAllModules(customer_uuid);
    return response;
  }
);
export const exportInteractionsTableByHowSolved = createAsyncThunk(
  "exportInteractionsTableByHowSolved",
  async (data, thunkAPI) => {
    const responses = await ExportInteractionsTableByHowSolved(
      data.how_solved,
      data.start_date,
      data.end_date
    );
    downloadFile(responses.response.data, responses.filename);
  }
);
export const exportInteractionsTableByWorkload = createAsyncThunk(
  "exportInteractionsTableByWorkload",
  async (data, thunkAPI) => {
    const responses = await ExportInteractionsTableByWorkload(
      data.workload_type,
      data.start_date,
      data.end_date,
      data.source_channel_names
    );
    downloadFile(responses.response.data, responses.filename);
  }
);

export const getAllBuHavingPgUnderUser = createAsyncThunk(
  "getAllBuHavingPgUnderUser",
  async (thunkAPI) => {
    const response = await GetAllBuHavingPgUnderUser();
    return response;
  }
);

export const getHighlevelStats = createAsyncThunk(
  "getHighlevelStats",
  async (data, thunkAPI) => {
    const response = await GetHighlevelStats(
      data.business_unit_uuid,
      data.end_date,
      data.start_date,
      data.support_channel
    );
    return response;
  }
);

export const getCustomerFeedbackChart = createAsyncThunk(
  "getCustomerFeedbackChart",
  async (data, thunkAPI) => {
    const response = await GetCustomerFeedbackChart(
      data.business_unit_uuid,
      data.end_date,
      data.start_date,
      data.support_channel
    );
    return response;
  }
);

export const getCustomerEffortChart = createAsyncThunk(
  "getCustomerEffortChart",
  async (data, thunkAPI) => {
    const response = await GetCustomerEffortChart(
      data.business_unit_uuid,
      data.end_date,
      data.start_date,
      data.support_channel
    );
    return response;
  }
);

export const getInteractionsCount = createAsyncThunk(
  "getInteractionsCount",
  async (data, thunkAPI) => {
    const response = await GetInteractionsCount(data.start_date, data.end_date);
    return response;
  }
);
export const getSavingsChart = createAsyncThunk(
  "getSavingsChart",
  async (data, thunkAPI) => {
    const response = await GetSavingsChart(data.start_date, data.end_date);
    return response;
  }
);
export const getCountChart = createAsyncThunk(
  "getCountChart",
  async (data, thunkAPI) => {
    const response = await GetCountChart(data.start_date, data.end_date);
    return response;
  }
);
export const getInteractionsCountByHowSolved = createAsyncThunk(
  "getInteractionsCountByHowSolved",
  async (data, thunkAPI) => {
    const response = await GetInteractionsCountByHowSolved(
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getInteractionsCountByWorkload = createAsyncThunk(
  "getInteractionsCountByWorkload",
  async (data, thunkAPI) => {
    const response = await GetInteractionsCountByWorkload(
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getInteractionsTableByHowSolved = createAsyncThunk(
  "getInteractionsTableByHowSolved",
  async (data, thunkAPI) => {
    const response = await GetInteractionsTableByHowSolved(
      data.page_no,
      data.page_size,
      data.how_solved,
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getInteractionsTableByWorkload = createAsyncThunk(
  "getInteractionsTableByWorkload",
  async (data, thunkAPI) => {
    const response = await GetInteractionsTableByWorkload(
      data.page_no,
      data.page_size,
      data.workload_type,
      data.start_date,
      data.end_date,
      data.source_channel_names
    );
    return response;
  }
);
export const getInteractionsSourceChannelByWorkload = createAsyncThunk(
  "getInteractionsSourceChannelByWorkload",
  async (data, thunkAPI) => {
    const response = await GetInteractionsSourceChannelByWorkload(
      data.workload_type,
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getSourceChannelTotalCountPieChart = createAsyncThunk(
  "getSourceChannelTotalCountPieChart",
  async (data, thunkAPI) => {
    const response = await GetSourceChannelTotalCountPieChart(
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getEndChannelTotalCountPieChart = createAsyncThunk(
  "getEndChannelTotalCountPieChart",
  async (data, thunkAPI) => {
    const response = await GetEndChannelTotalCountPieChart(
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getSourceChannelTotalCountBarChart = createAsyncThunk(
  "getSourceChannelTotalCountBarChart",
  async (data, thunkAPI) => {
    const response = await GetSourceChannelTotalCountBarChart(
      data.start_date,
      data.end_date
    );
    return response;
  }
);
export const getEndChannelTotalCountBarChart = createAsyncThunk(
  "getEndChannelTotalCountBarChart",
  async (data, thunkAPI) => {
    const response = await GetEndChannelTotalCountBarChart(
      data.start_date,
      data.end_date
    );
    return response;
  }
);

export const getInteractionsHomePage = createAsyncThunk(
  "getInteractionsHomePage",
  async (thunkAPI) => {
    const response = await GetInteractionsHomePage(
    
    
    );
    return response;
  }
);
const leaderboardSlice = createSlice({
  name: "leaderboardSlice",
  initialState: {
    active_channels: [],
    business_units: [],

    customer_effort_details: null,
    usagestats_details: null,
    interactions_count: null,
    interactions_count_by_workload: null,

    interactions_table: null,
    source_channel_pie_chart_details: null,
    end_channel_pie_chart_details: null,
    source_channel_bar_chart_details: null,
    end_channel_bar_chart_details: null,
    moduledetails: [],
    savings_chart_details: null,
    count_chart_details: null,
    source_channel_count: null,
    home_page_interactions_details:null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveChannels.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.active_channels = action.payload.data;
      })
      .addCase(getAllModule.fulfilled, (state, action) => {
        if (action.payload.status === 200) {
          state.moduledetails = action.payload.data.data;
          if (action.payload.data?.data?.length > 0) {
            localStorage.setItem(
              "md",
              JSON.stringify(action.payload.data.data)
            );
          }
        }
      })
      .addCase(getAllBuHavingPgUnderUser.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.business_units = action.payload.data;
      })
      .addCase(getCustomerEffortChart.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.customer_effort_details = action.payload.data;
      })
      .addCase(getHighlevelStats.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.usagestats_details = action.payload.data;
      })
      .addCase(getInteractionsCountByHowSolved.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.interactions_count = action.payload.data.data;
      })

      .addCase(getInteractionsCountByWorkload.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.interactions_count_by_workload = action.payload.data.data;
      })

      .addCase(getInteractionsTableByHowSolved.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.interactions_table = action.payload.data;
      })
      .addCase(getInteractionsTableByWorkload.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.interactions_table = action.payload.data;
      })
      .addCase(
        getSourceChannelTotalCountPieChart.fulfilled,
        (state, action) => {
          if (action.payload.status === 200)
            state.source_channel_pie_chart_details = action.payload.data.data;
        }
      )
      .addCase(getEndChannelTotalCountPieChart.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.end_channel_pie_chart_details = action.payload.data.data;
      })
      .addCase(
        getSourceChannelTotalCountBarChart.fulfilled,
        (state, action) => {
          if (action.payload.status === 200)
            state.source_channel_bar_chart_details = action.payload.data.data;
        }
      )
      .addCase(getEndChannelTotalCountBarChart.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.end_channel_bar_chart_details = action.payload.data.data;
      })
      .addCase(getSavingsChart.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.savings_chart_details = action.payload.data.data;
      })
      .addCase(getCountChart.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.count_chart_details = action.payload.data.data;
      })
      .addCase(getInteractionsHomePage.fulfilled, (state, action) => {
        if (action.payload.status === 200)
          state.home_page_interactions_details = action.payload.data.data;
      })
      .addCase(
        getInteractionsSourceChannelByWorkload.fulfilled,
        (state, action) => {
          if (action.payload.status === 200)
            state.source_channel_count = action.payload.data.data;
        }
      );
  },
});

export default leaderboardSlice.reducer;
