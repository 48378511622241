import axios from "../../../utils/axios"
export const GetUserInfoAPI = async () => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY + `oauth/${localStorage.getItem('ci')}/user_info`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
    }
  );
  return { data: response.data, status: response.status };
};

export const GetSlackAuth= async () => {
  let response = await axios(
    process.env.REACT_APP_SLACK + `slackbot/authorize/${localStorage.getItem('ci')}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("at"),
      },
    }
  );
  return { data: response.data, status: response.status };
};
