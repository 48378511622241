import React from 'react'

function ProfileIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16348 24.8366 0 16 0C7.16342 0 0 7.16348 0 16C0 24.8366 7.16342 32 16 32Z" fill="#B0B5C3"/>
    <path d="M16.0006 7.88696C12.6886 7.88696 10.0005 10.575 10.0005 13.887C10.0005 17.1349 12.5446 19.7749 15.9205 19.8709C15.9685 19.8709 16.0326 19.8709 16.0645 19.8709C16.0966 19.8709 16.1446 19.8709 16.1766 19.8709C16.1926 19.8709 16.2085 19.8709 16.2085 19.8709C19.4406 19.7589 21.9846 17.1349 22.0006 13.887C22.0006 10.575 19.3126 7.88696 16.0006 7.88696Z" fill="#B0B5C3"/>
    <path d="M26.8488 27.7591C24.0009 30.3831 20.1928 31.9991 16.0008 31.9991C11.8089 31.9991 8.0008 30.3831 5.15283 27.7591C5.53682 26.3031 6.57685 24.9751 8.0968 23.9511C12.4648 21.0391 19.5688 21.0391 23.9049 23.9511C25.4409 24.9751 26.4648 26.3031 26.8488 27.7591Z" fill="#B0B5C3"/>
    </svg>
    
  )
}

export default ProfileIcon