import React from 'react'

function SlackIcon() {
  return (
<svg width="24" height="24" viewBox="0 0 683 683" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M238.933 20C217.733 25.6 200.533 39.2 191.467 57.4667C180.133 80.6667 180.133 104.667 191.467 127.867C200.533 146.4 217.867 159.867 239.467 165.333C248.533 167.733 254.267 168 288.933 168C326.667 168 328.133 167.867 330.8 165.2C333.467 162.533 333.467 161.467 333.067 118.933L332.667 75.3334L328.8 66C313.6 29.0667 276.667 10.1334 238.933 20ZM268.8 37.4667C284.933 40.4 300 50.8 307.067 63.6C313.6 75.8667 314.667 83.0667 314.667 117.6V149.333H283.067C260.4 149.333 249.333 148.8 244.533 147.467C216.133 139.867 197.6 111.867 202.4 83.8667C206.133 62.4 220 45.7334 239.333 39.6C251.333 35.8667 257.333 35.3334 268.8 37.4667Z" fill="black"/>
<path d="M407.467 19.4667C379.6 25.4667 358 46.8 351.333 74.8C348.4 87.6 348.4 264.933 351.333 276.667C358.133 303.467 379.467 324.667 406.667 331.467C418.667 334.533 439.067 333.467 450 329.333C461.6 324.933 475.2 315.6 482.267 306.933C492.8 294 500 275.2 500 260.667C500 253.467 496.533 249.333 490.667 249.333C484.667 249.333 481.867 253.067 480.933 262.4C477.867 291.467 457.6 311.867 429.333 314.267C407.067 316.133 384.267 304.133 374.8 285.6C368.133 272.4 368 271.067 368 175.333C368 77.8667 368 77.7334 375.6 63.6C377.6 60 382.133 54.4 385.733 51.2C413.467 26.2667 458.133 33.8667 474.533 66.2667C481.067 79.3334 481.333 82.5334 481.333 155.6C481.333 222.267 481.467 224.133 484 226.667C485.467 228.133 488.4 229.333 490.667 229.333C492.933 229.333 495.867 228.133 497.333 226.667C499.867 224.133 500 222.267 500 154.267C500 89.0667 499.867 83.7334 497.333 74.1334C490.133 45.6 469.067 25.3334 440.667 19.3334C429.067 16.9334 419.333 16.9334 407.467 19.4667Z" fill="black"/>
<path d="M76 184.8C48 190.8 26 212.267 19.3333 240.133C13.2 266.667 21.0667 294.667 39.7333 312.133C50.6667 322.4 59.0667 327.2 72.8 330.667C82.5333 333.2 87.7333 333.333 175.333 333.333C262.933 333.333 268.133 333.2 277.867 330.667C292.133 327.067 300.4 322.267 311.467 311.333C353.467 269.733 332 196.8 274 184.667C255.867 180.8 249.333 182.933 249.333 192.667C249.333 198.667 252.533 200.667 265.067 202.4C291.333 206.133 309.733 224.133 313.733 249.733C317.333 272.533 306 296.667 286.933 307.067C273.067 314.667 272.667 314.667 175.733 314.667C114.933 314.667 84.5333 314.133 79.7333 313.2C52.6667 307.467 34.2667 282.267 36.4 254.133C38.1333 230.533 50.4 214.267 73.3333 204.933C80.5333 202 81.7333 202 150.667 201.6C228.933 201.2 225.333 201.6 225.333 191.867C225.333 182.4 227.867 182.667 152 182.8C99.6 182.8 82.8 183.333 76 184.8Z" fill="black"/>
<path d="M573.867 184.667C546.533 190.667 523.333 213.733 516.667 241.467C515.2 248 514.667 258.8 514.667 288.933C514.667 326.667 514.8 328.133 517.467 330.8C520.133 333.467 521.067 333.467 563.733 333.067C604.667 332.533 607.867 332.4 615.2 329.6C646.133 318.133 665.333 290.8 665.333 258.667C665.333 237.6 658 219.467 644 205.2C626.133 187.067 599.067 179.2 573.867 184.667ZM610 205.467C631.467 213.467 644.533 230.933 646.267 254.133C648.4 282.267 630 307.467 602.933 313.2C598.8 314 582.667 314.667 564.667 314.667H533.333V284.8C533.333 268.4 534 251.867 534.667 248C538.8 226 557.2 207.2 578.933 202.667C587.067 200.933 601.333 202.267 610 205.467Z" fill="black"/>
<path d="M73.3334 351.467C40 360.267 17.3334 389.6 17.3334 424C17.4667 460.8 41.2 490.533 76.6667 498C94 501.6 109.733 499.733 126.133 491.733C138.4 485.867 153.733 470.667 159.6 458.533C167.2 443.2 168 436.933 168 393.6C168 356.4 167.867 354.533 165.333 352C162.8 349.467 160.933 349.333 121.733 349.467C90.2667 349.467 78.9334 350 73.3334 351.467ZM149.333 397.6C149.333 440.133 146.4 451.067 131.067 465.467C118.667 477.2 102 482.267 84 479.867C54.1334 475.6 34.6667 451.467 36.2667 420.667C37.0667 406 43.2 392.667 53.7334 383.2C60.4 377.2 73.0667 370.267 80 368.933C81.8667 368.667 98.1334 368.267 116.4 368.133L149.333 368V397.6Z" fill="black"/>
<path d="M238.8 351.467C212.267 358.533 189.6 382.4 184.133 409.067C183.2 413.733 182.667 446.667 182.667 507.2C182.667 593.467 182.8 598.8 185.333 608.533C192.667 637.333 213.6 657.333 242.667 663.467C253.867 665.867 269.867 665.333 280.133 662.133C305.867 654.267 325.2 633.733 331.333 607.867C334.267 595.067 334.267 417.733 331.333 406C324.533 379.467 303.2 358.133 276.667 351.333C266.8 348.8 248.4 348.933 238.8 351.467ZM281.333 372.933C296.933 380.267 307.067 391.6 312 407.733C314.533 415.867 314.667 420.4 314.667 506.533C314.667 603.2 314.533 604.4 307.867 617.6C303.733 625.6 292.533 636.267 283.867 640.4C255.333 653.867 220.667 642.667 208 615.733C205.867 611.2 203.467 604.4 202.667 600.667C201.733 596.133 201.333 563.467 201.6 502.533C202 416.133 202.133 411.067 204.533 405.333C211.733 388.4 223.867 376.533 239.867 370.933C252.533 366.533 269.067 367.333 281.333 372.933Z" fill="black"/>
<path d="M404.267 352C390.533 355.6 382.133 360.533 371.2 371.333C355.467 387.067 348.267 406.4 349.733 429.333C352 464 375.067 490.933 408.667 498C422.4 500.933 592.267 500.933 606 498C641.467 490.533 665.2 460.8 665.333 424C665.333 389.467 642.4 360 608.667 351.333C602 349.6 586.8 349.333 507.333 349.467C419.867 349.467 413.333 349.6 404.267 352ZM602.933 369.467C629.867 375.067 648.4 400.4 646.267 428.533C644.533 451.733 632 468.533 609.6 477.6L602 480.667L511.467 481.067C448.933 481.333 418.8 480.933 413.867 480C398.667 476.8 383.067 465.733 375.467 452.667C362.933 431.333 366.667 402.533 384.267 384.933C391.2 378 402.667 371.333 411.067 369.333C418.133 367.6 594.8 367.733 602.933 369.467Z" fill="black"/>
<path d="M351.867 517.467C349.2 520.133 349.2 521.2 349.6 563.733L350 607.333L353.867 616.667C369.067 653.467 405.6 672.267 443.2 662.667C472 655.333 492 634.4 498.133 605.333C504.8 573.6 489.2 538.8 461.733 524.267C445.733 515.867 437.733 514.667 393.733 514.667C356 514.667 354.533 514.8 351.867 517.467ZM436.267 534.8C472.4 542.533 490.933 581.067 474.8 615.467C457.2 652.933 403.2 657.2 378.667 622.933C369.2 609.867 368 603.2 368 565.867V533.333H398.667C416.133 533.333 432.267 534 436.267 534.8Z" fill="black"/>
</svg>

  )
}

export default SlackIcon