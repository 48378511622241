

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetAllSubDatasourceByModules } from '../actions/dataIntegration/subdatasourcActions';


export const getAllDataSource = createAsyncThunk('dsinfo',
    async (module_uuids, thunkAPI) => {
        const response = await GetAllSubDatasourceByModules(module_uuids)
        return response
    }
)

const DataSourceSlice = createSlice({
    name: 'datasource',
    initialState: {
        ds_details: null,
        status: null,
        selected_ds: null

    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getAllDataSource.pending, (state, action) => {
            state.status = false
        })
            .addCase(getAllDataSource.fulfilled, (state, action) => {
                if(action.payload.status===200){
                state.ds_details = action.payload.data
                state.status = true
                }
                else{
                    state.status=false
                }

            })
            .addCase(getAllDataSource.rejected, (state, action) => {
                state.status = false
            })
    },
})

export default DataSourceSlice.reducer