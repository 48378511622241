import axios from "../../utils/axios";

export const GetBusinessUnitsByUser = async () => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY +
      `organization/${localStorage.getItem("ci")}/get_all_bu_having_pg_under_user/${JSON.parse(localStorage.getItem("ud")).uuid}`,
    {
      method: "GET",
    }
  );

  return { data: response.data, status: response.status };
};
