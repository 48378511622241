import { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { useState } from "react";
import './simpleTable.scss'

import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ResizableTable = ({
  onTableRowClick,
  paginationDetails,
  onTablePaginationChange,
  columns,
  data,
  rowCount,
  manualPagination
}) => {
 
  const [pagination, setPagination] = useState({
    pageIndex: paginationDetails.page,
    pageSize: paginationDetails.pageSize, //customize the default page size
  });

  
  const handlePaginationChange = (newPagination) => {
    setPagination(newPagination);
  };

  useEffect(() => {
    onTablePaginationChange({
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode:"grid",
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 80,
      size: 160, //default size is usually 180
    },
    enableColumnResizing: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableFilters: false,
    enableColumnOrdering: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableSorting: false,
    enableTopToolbar: false,
    manualPagination:true,
    rowCount: rowCount,
    muiTableBodyRowProps:({row})=>({
onClick:()=>onTableRowClick(row),


    }),
    onPaginationChange: (newPaginationModel) =>
      handlePaginationChange(newPaginationModel),
    state: { pagination },

    getRowId: (originalRow) => originalRow.id,
  
    
  
   
    
    muiTablePaperProps: {
      elevation: 0,
    },

    columnResizeMode: "onChange", //default
  });

  return <MaterialReactTable table={table} />;
};

export default ResizableTable;
