import axios from "../../../utils/axios";

export const GetAllBuHavingPgUnderUser = async () => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY+
    `organization/${localStorage.getItem("ci")}/get_all_bu_having_pg_under_user/${localStorage.getItem("ui")}`,
    {
      method: "GET",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
    
    }
  );
  return { data: response.data, status: response.status };
};

export const GetAllPgInBuForUser = async (organization_uuid) => {
  let response = await axios(
    process.env.REACT_APP_IDENTITY+
    `organization/${localStorage.getItem("ci")}/get_all_pg_in_bu_for_user`,
    {
      method: "POST",
      headers: {
        accept: "application/json,text/plain",
        "Content-Type": "application/json",
      },
      data:{
        organization_uuid
      }
    
    }
  );
  return { data: response.data, status: response.status };
};

